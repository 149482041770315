<!--
 * @Description: 商品菜单
 * @Autor: zhangzhang
 * @Date: 2021-06-10 16:00:34
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2022-03-29 09:42:29
-->
<template>
  <SideBar :groups="groups"></SideBar>
</template>

<script>
import SideBar from '@/components/SideBar';
export default {
  components: {
    SideBar,
  },

  data() {
    return {
      groups: [
        {
          label: '用户管理',
          list: [
            {
              name: '用户列表',
              path: '/mall/user/userList',
            },
            {
              name: '反馈意见',
              path: '/mall/user/feedback',
            },
          ],
        },
        {
          label: '会员管理',
          list: [
            {
              name: '会员用户',
              path: '/mall/user/vipList',
            },
          ],
        },
        {
          label: '服务者管理',
          list: [
            {
              name: '服务者用户',
              path: '/mall/user/sellerList',
            },
            {
              name: '服务者审核',
              path: '/mall/user/serviceUser',
            },
          ],
        },
      ],
    };
  },
};
</script>
